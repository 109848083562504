<template>
    <div>
        <div
            v-if="loading"
            class="py-5">
            <loader label="Checking available inventory options..." />
        </div>
        <template v-else>
            <styled-callout
                v-if="!websiteInventoryId && !dealerInventoryType"
                class="layout align-center mb-4">
                <div class="flex shrink mr-3">
                    <status-icon
                        value="warning"
                        size="25" />
                </div>
                <div class="flex">
                    Website inventory cannot be retrieved from {{ dealerHostname }}
                    so you will need to use feed inventory for this account.
                </div>
            </styled-callout>
            <styled-callout
                v-if="dealerInventoryType"
                class="layout align-center mb-4">
                <div class="flex shrink mr-3">
                    <status-icon size="25" />
                </div>
                <div class="flex">
                    {{ currentDealer.name }}'s {{ dealerInventoryType.replace('_', ' ') }} has
                    already been configured and cannot be changed in onboarding. Use
                    the dealer dashboard if you'd like to change it.
                </div>
            </styled-callout>
            <div class="mb-5">
                <h2>How Would You Like to Retrieve Inventory?</h2>
                <p class="mb-4">
                    Choose the way you'd like BuyerBridge to retrieve inventory
                    for {{ currentDealer.name }}. You can change this option after
                    onboarding if you change your mind!
                </p>
                <inventory-switcher
                    :inventory-type="inventoryType"
                    :website-inventory-id="websiteInventoryId"
                    :dealer-inventory-type="dealerInventoryType"
                    @set-inventory-type="setInventoryType" />
            </div>
            <div class="layout align-center">
                <back-button
                    @click="$store.dispatch('goToPreviousStep')" />
                <styled-button
                    class="ml-auto"
                    :disabled="!valid"
                    @click="$store.dispatch('goToNextStep')">
                    CONTINUE
                </styled-button>
            </div>
        </template>
    </div>
</template>

<script>
import BackButton from '@/components/globals/BackButton';
import Loader from '@/components/globals/Loader';
import StyledCallout from '@/components/globals/StyledCallout';
import StatusIcon from '@/components/globals/StatusIcon';
import StyledButton from '@/components/globals/StyledButton';
import InventorySwitcher from '@/components/globals/InventorySwitcher';
import { mapGetters, mapState } from 'vuex';
import { UPDATE_ONBOARDING_DATA } from '@/store/mutation-types';

export default {
    name: 'OnboardingInventoryType',
    components: {
        BackButton,
        Loader,
        StatusIcon,
        StyledCallout,
        StyledButton,
        InventorySwitcher,
    },
    data() {
        return {
            hasWebsiteInventorySupport: false,
            loading: false
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            onboardingData: (state) => state.onboarding.data
        }),
        ...mapGetters([
            'dealerInventoryType',
            'dealerHostname',
            'dealerRootDomain'
        ]),
        valid() {
            if (this.dealerInventoryType) {
                return true;
            }

            return !!this.inventoryType;
        },
        inventoryType: {
            get() {
                return this.onboardingData.inventoryType || null;
            },
            set(type) {
                this.$store.commit(UPDATE_ONBOARDING_DATA, {
                    inventoryType: type
                });
            }
        },
        websiteInventoryId: {
            get() {
                return this.onboardingData.websiteInventoryId || null;
            },
            set(id) {
                this.$store.commit(UPDATE_ONBOARDING_DATA, {
                    websiteInventoryId: id
                });
            }
        },

    },
    created() {
        this.init();
    },
    methods: {
        setInventoryType(type) {
            this.inventoryType = type;
        },
        async init() {
            // Default to the dealer's inventory type
            if (this.dealerInventoryType) {
                this.inventoryType = this.dealerInventoryType;
            } else {
                this.loading = true;
                await this.checkWebsiteInventorySupport();
                this.loading = false;
            }
        },
        async checkWebsiteInventorySupport() {
            const { dealer_id } = await this.$apiRepository.checkWebsiteInventorySupport(this.dealerRootDomain);
            this.websiteInventoryId = Boolean(dealer_id);
        }
    }
};
</script>
