<template>
    <div>
        <v-radio-group
            v-model="selectedInventoryType"
            :disabled="!!dealerInventoryType">
            <!-- website inventory -->
            <styled-tooltip
                position="top"
                :disabled="!!websiteInventoryId">
                <template #content>
                    Website inventory is not supported on {{ dealerHostname }}.
                    Please use feed inventory.
                </template>
                <v-radio
                    label="Use Website Inventory"
                    value="website_inventory"
                    color="primary"
                    :disabled="!websiteInventoryId" />
            </styled-tooltip>
            <p class="inventory-description mb-4">
                Our automagick scraper will download inventory from your website
                on a regular basis. You will have the option to verify
                data and prices during onboarding and can change to feed
                inventory at a later date if you change your mind. Otherwise,
                we recommend website inventory to get up and running quickly!
            </p>

            <!-- feed inventory -->
            <v-radio
                label="Use Feed Inventory"
                value="feed_inventory"
                color="primary" />
            <p class="inventory-description">
                Feed inventory will require you to work with {{ currentDealer.name }}'s
                data provider to export inventory to BuyerBridge. Feed data can
                sometimes be more reliable than the scraper but can take while
                to obtain. You should use this option if you know you want feed
                inventory.
            </p>
        </v-radio-group>
    </div>
</template>

<script>
import StyledTooltip from '@/components/globals/StyledTooltip.vue';
import { mapState, mapGetters } from 'vuex';

export default {
    components: {
        StyledTooltip
    },
    props: {
        inventoryType: {
            type: String,
            default: ''
        },
        dealerInventoryType: {
            type: String,
            default: ''
        },
        websiteInventoryId: {
            type: [String, Number],
            default: null
        },
    },
    data() {
        return {
            selectedInventoryType: ''
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
        }),
        ...mapGetters([
            'dealerHostname',
        ]),
    },
    watch: {
        inventoryType(value) {
            if (this.selectedInventoryType !== value) {
                this.selectedInventoryType = value;
            }
        },
        selectedInventoryType(value) {
            this.$emit('set-inventory-type', value);
        }
    },
    mounted() {
        this.selectedInventoryType = this.inventoryType;
    }
};
</script>

<style lang="scss" scoped>
.inventory-description {
    padding-left: 33px;
}
</style>
